// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Datepicker
@import '~jquery-ui/themes/base/all.css';

body {
    font-family: 'FuturaBT-Book',Arial,Helvetica,sans-serif;
    background-color: white;
    color: #123f49;
}

.header {
    margin-bottom: 40px;
}

.logo {
    .svg-logo {
        display: inline-block;
        height: 50px;
    }

    .part-one {
        fill: #000;
        width: 88px;
        margin-right: 5px;
    }

    .part-two {
        transition: fill,.5s ease;
        width: 28px;
        fill: #cd1316;
    }
}

.nav a {
    color: #123f49;
}

.btn-primary {
    background-color: #6fadb0;
    border-radius: 0;
    border: 0;

    &:hover, &:active {
        background-color: #004d5f;
    }
}

.alert {
    text-align: center;
}

// .form-control {
//     background-color: #eee;
// }

.form-control[readonly] {
    background-color: #d4d9dd;
}

.prospects a {
    color: inherit;
    text-decoration: underline;
}

.prospects h5 a {
    text-decoration: none;
}

a.delete {
    color: #cd1316;
}

// a.btn {
//     display: inline-block;
//     margin-bottom: 8px;
//     padding: 12px 50px;
//     text-decoration: none;
//     font-size: .85rem;
//     border: none;
//     border-radius: 50px;
//     transition: all .3s ease-in-out;
//     cursor: pointer;

//     &--border {
//         color: #212d30;
//         background-color: transparent;
//         font-weight: 700;
//         border: 1px solid #212d30;
//     }
// }

i.fa-solid {
    margin-right: 5px;
}

ul.nav li.nav-item {
    a.nav-link:hover {
        color: #44c7f1;
    }
    // a.btn {
    //     border-color: #6fadb0;
    //     color: #6fadb0;

    //     &:hover, &:active {
    //         color: #fff;
    //         background-color: #44c7f1;
    //     }

    //     &--active {
    //         color: #fff;
    //         background-color: #6fadb0;

    //         &:hover, &:active {
    //             border-color: #004d5f;
    //             background-color: #004d5f;
    //         }
    //     }
    // }
}

.officials_warning {
    color: #cd1316;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.login {

    border: 4px dashed #6fadb0ad;
    border-radius: 15px;
    padding: 30px;
    margin-top: 100px;

    button.bankid-button {
        background-color: transparent;
        border: none;
        width: 90%;
        padding: 10px;

        &.selected {
            border-bottom: 4px solid #44c7f1;
        }
    }

    .login-dialogue {
        height: 212px;
        display: none;
    }

    img.qr {
        width: 212px;
        height: 212px;
    }
}

.prospect-card {
    border: 2px solid #44c7f1;
    border-radius: 4px;
    padding: 20px 10px;

    .showIfReady {
        display: none;
    }

    .hideIfReady {
        display: block;
    }

    &.ready {
        .showIfReady {
            display: block;
        }

        .hideIfReady {
            display: none;
        }
    }
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: inherit;
}